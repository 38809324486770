import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import { Collection } from 'types/collection.type';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { useAMDataDisplayStateContext } from 'components/asset-management/context/asset-management-display-state.context';
import { useAMCollectionSelection } from 'components/asset-management/hooks';

interface Props {
    activeCollection: Collection | null;
    onClose: () => void;
}

const AssetManagementCollectionMenuItemsViewDetails = ({ activeCollection, onClose }: Props) => {
    const { detailbarOpen, setDetailbarOpen } = useAMDataDisplayStateContext();
    const { isSelected, setSelectedCollections } = useAMCollectionSelection(activeCollection?.id);

    const handleDetail = () => {
        if (detailbarOpen && isSelected) {
            setSelectedCollections([]);
            setDetailbarOpen(false);
        } else {
            if (activeCollection) setSelectedCollections([activeCollection.id]);
            setDetailbarOpen(true);
        }
        onClose();
    };

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                handleDetail();
            }}>
            <ListItemIcon>
                <Icon fontSize="small">info_outline</Icon>
            </ListItemIcon>
            <ListItemText>
                {detailbarOpen && isSelected ? Translation.get('actions.hideDetails', 'common') : Translation.get('actions.viewDetails', 'common')}
            </ListItemText>
        </MenuItem>
    );
};

export default AssetManagementCollectionMenuItemsViewDetails;
