import React, { useEffect, useState } from 'react';
import { Collection } from 'types/collection.type';
import AssetManagementCollectionMenuItemsViewDetails from '../collection-menu-items/view-details';
import AssetManagementCollectionMenuItemsDelete from '../collection-menu-items/delete';
import AssetManagementCollectionMenuItemsCreateSubColl from '../collection-menu-items/create-subcollection';
import AssetManagementMenuItemsWrapper, { AssetManagerMenuButtonType } from '../menu-items-wrapper';
import AssetManagementCollectionDialog from '../dialogs/collection-dialog';
import StopPropagationWrapper from '../stop-propagation-wrapper';
import AssetManagementRemoveCollectionDialog from '../dialogs/remove-collection-dialog';

interface Props {
    collection: Collection;
    buttonType?: AssetManagerMenuButtonType;
    onSetKeepMenuMounted?: (open: boolean) => void; // Used in case we want a callback to the parent component when the menu or dialogs are opened or closed.
}

const AssetManagementCollectionMenu = ({ collection, buttonType, onSetKeepMenuMounted }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const [createCollectionDialogOpen, setCreateCollectionDialogOpen] = useState<boolean>(false);
    const [removeCollectionDialogOpen, setRemoveCollectionDialogOpen] = useState<boolean>(false);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (Boolean(anchorEl) || createCollectionDialogOpen || removeCollectionDialogOpen) {
            if (onSetKeepMenuMounted) onSetKeepMenuMounted(true);
        } else {
            if (onSetKeepMenuMounted) onSetKeepMenuMounted(false);
        }
    }, [anchorEl, createCollectionDialogOpen, removeCollectionDialogOpen]);

    return (
        <StopPropagationWrapper>
            <AssetManagementMenuItemsWrapper buttonType={buttonType} open={open} anchorEl={anchorEl} onSetAnchorEl={setAnchorEl}>
                <AssetManagementCollectionMenuItemsCreateSubColl
                    onSetCreateCollectionDialogOpen={() => {
                        setCreateCollectionDialogOpen(true);
                        setAnchorEl(undefined);
                    }}
                />
                <AssetManagementCollectionMenuItemsViewDetails activeCollection={collection} onClose={() => setAnchorEl(undefined)} />
                <AssetManagementCollectionMenuItemsDelete
                    onSetRemoveCollectionDialogOpen={() => {
                        setRemoveCollectionDialogOpen(true);
                        setAnchorEl(undefined);
                    }}
                />
            </AssetManagementMenuItemsWrapper>
            {createCollectionDialogOpen && (
                <AssetManagementCollectionDialog activeCollection={collection} onClose={() => setCreateCollectionDialogOpen(false)} />
            )}
            {removeCollectionDialogOpen && (
                <AssetManagementRemoveCollectionDialog activeCollection={collection} onClose={() => setRemoveCollectionDialogOpen(false)} />
            )}
        </StopPropagationWrapper>
    );
};

export default AssetManagementCollectionMenu;
