import React, { useEffect, useRef } from 'react';
import CircularProgress from 'components/ui-components-v2/CircularProgress';

import './styles/main.scss';

interface Props {
    children: React.ReactNode | React.ReactNode[]; // List of items as children, or a component wrapping the list.
    hasMore: boolean; // Whether there are more items to load
    loading: boolean; // Whether a loadMore is in progress.
    container?: HTMLElement | null; // Optional root element for the observer
    rootMargin?: number; // Root margin for the observer (default is 200 (px))
    loadMore: () => void; // Function to load more items
}

const AssetManagementInfiniteScroll = ({ children, loadMore, hasMore, loading = false, container = null, rootMargin = 200 }: Props) => {
    const loaderRef = useRef<HTMLDivElement | null>(null); // Ref for the loader element

    const checkIfLoaderIsInView = () => {
        if (loaderRef.current && hasMore && !loading) {
            const loader = loaderRef.current.getBoundingClientRect();
            const scrollContainer = container || window;
            const viewportHeight = scrollContainer instanceof HTMLElement ? scrollContainer.clientHeight : window.innerHeight;
            const scrollPostion = scrollContainer instanceof HTMLElement ? scrollContainer.scrollTop : window.scrollY;
            const trigger = (() => {
                if (scrollPostion > 0) return 2 * viewportHeight;
                return viewportHeight + rootMargin;
            })();
            const shouldLoad = loader.top <= trigger;
            if (shouldLoad) {
                loadMore();
            }
        }
    };

    useEffect(() => {
        const scrollContainer = container || window;
        scrollContainer.addEventListener('scroll', checkIfLoaderIsInView);
        checkIfLoaderIsInView(); // Initial check

        return () => {
            scrollContainer.removeEventListener('scroll', checkIfLoaderIsInView);
        };
    }, [hasMore, loading, loadMore, container, rootMargin]);

    return (
        <div>
            {children}
            {hasMore && (
                <div ref={loaderRef} className="asset-management-infinite-scroll">
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default AssetManagementInfiniteScroll;
