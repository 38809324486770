import React, { useState } from 'react';
import { Collection } from 'types/collection.type';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import { useAMFetch, useAMNavigation } from '../../hooks/';

interface Props {
    activeCollection: Collection | null;
    onClose: () => void;
}

const AssetManagementRemoveCollectionDialog = ({ activeCollection, onClose }: Props) => {
    const [waiting, setWaiting] = useState(false);
    const { storeName } = useAssetManagementConfigContext();
    const { fetchCollections, fetchCollectionsTree } = useAMFetch();
    const { navigateFromRoot } = useAMNavigation();

    const removeCollection = async () => {
        if (!activeCollection) return;
        await AssetManagementService.deleteCollection(activeCollection.id);
        fetchCollectionsTree();
        fetchCollections(true);
        if (activeCollection.parentId) {
            // In case the removed collection is a sub collection, remove the content of it's parent from Redux so it's not mentioned there any more.
            ComponentStoreHelpers.removeItem(storeName, `collectionsContent.${activeCollection.parentId}`);
            navigateFromRoot(`/collection/${activeCollection.parentId}`);
        } else {
            navigateFromRoot(`/collections`);
        }
        ComponentStoreHelpers.removeItem(storeName, `collectionsContent.${activeCollection.id}`);
        onClose();
    };

    return (
        <ConfirmDialog
            onConfirm={removeCollection}
            confirmColor={'error'}
            confirmText={Translation.get('actions.remove', 'common')}
            onClose={onClose}
            open
            title={Translation.get('dialogs.removeCollection.title', 'asset-management')}
            description={Translation.get('dialogs.removeCollection.desc', 'asset-management', { title: activeCollection?.title || '' })}
        />
    );
};

export default AssetManagementRemoveCollectionDialog;
