import React, { cloneElement, RefObject } from 'react';
import classNames from 'classnames';
import { Collection } from 'types/collection.type';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import TruncatedTextWithTooltip from 'components/ui-components-cape/TurncatedTextWithTooltip';
import Typography from 'components/ui-components-v2/Typography';
import AssetManagementCollectionTileThumbnails from './thumbnails';
import AssetManagementCollectionTileDisplayOverlay from './display-overlay';

import '../styles/display.scss';

interface Props {
    tileRef?: RefObject<HTMLDivElement>;
    collection: Collection;
    collectionTileThumbnails?: React.ReactElement;
    hovered: boolean;
    children?: React.ReactElement;
    small?: boolean;
    className?: string;
    onHandleClick: (e: React.MouseEvent<HTMLElement>) => void;
    onSetHovered: (hovered: boolean) => void;
}

const AssetManagementCollectionTileDisplay = ({
    tileRef,
    collection,
    collectionTileThumbnails,
    hovered,
    small,
    children,
    className,
    onHandleClick,
    onSetHovered
}: Props) => {
    const { languageNameSpace } = useAssetManagementConfigContext();

    const buildCountText = () => {
        return `${collection.assetCount} ${Translation.get('asset', languageNameSpace, { count: collection.assetCount })} | ${collection.collectionCount} ${Translation.get('collection', 'asset-management', { count: collection.collectionCount })} `;
    };

    return (
        <div
            id={`collection_${collection.id}`}
            ref={tileRef}
            className={classNames('asset-management-collection-tile-display', className)}
            data-cy="assetManagement-collectionTileDisplay-div"
            onClick={onHandleClick}
            onMouseEnter={() => onSetHovered(true)}
            onMouseLeave={() => onSetHovered(false)}>
            {collectionTileThumbnails ? (
                cloneElement(collectionTileThumbnails, {
                    thumbnails: collection.thumbnails,
                    assetCount: collection.assetCount,
                    hovered,
                    children: (
                        <AssetManagementCollectionTileDisplayOverlay hovered={hovered} collection={collection}>
                            {children}
                        </AssetManagementCollectionTileDisplayOverlay>
                    )
                })
            ) : (
                <AssetManagementCollectionTileThumbnails thumbnails={collection.thumbnails} assetCount={collection.assetCount} hovered={hovered}>
                    <AssetManagementCollectionTileDisplayOverlay hovered={hovered} collection={collection}>
                        {children}
                    </AssetManagementCollectionTileDisplayOverlay>
                </AssetManagementCollectionTileThumbnails>
            )}
            <div className="asset-management-collection-tile-display__info">
                <TruncatedTextWithTooltip variant={small ? 'h5' : 'h4'}>{collection.title}</TruncatedTextWithTooltip>
                <Typography variant="body2" color="textSecondary" noWrap>
                    {buildCountText()}
                </Typography>
            </div>
        </div>
    );
};

export default AssetManagementCollectionTileDisplay;
