import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from 'components/ui-components-v2/Icon';
import {
    FormatOption,
    FrameTypeOption,
    SubSetOption
} from 'components/asset-management/components/version-release-comparison-dialog/types/asset-management-version-comparison-options.type';

import '../styles/status-icon.scss';

interface Props {
    option: FrameTypeOption | FormatOption | SubSetOption;
    reserveIconSpace: boolean;
}

const AssetManagementVersionReleaseComparisonDialogStatusIcon = ({ option, reserveIconSpace }: Props) => {
    if (option.latest && !option.released)
        return (
            <ListItemIcon className="asset-management-version-release-comparison-dialog-status-icon">
                <Icon color="success">add_circle_outline</Icon>
            </ListItemIcon>
        );
    if (!option.latest && option.released)
        return (
            <ListItemIcon className="asset-management-version-release-comparison-dialog-status-icon">
                <Icon color="error">remove_circle_outline</Icon>
            </ListItemIcon>
        );
    if (option.latest && option.released && option.interfaceChanged)
        return (
            <ListItemIcon className="asset-management-version-release-comparison-dialog-status-icon">
                <Icon color="warning">loop</Icon>
            </ListItemIcon>
        );
    if (option.latest && option.released && option.changed)
        return (
            <ListItemIcon className="asset-management-version-release-comparison-dialog-status-icon">
                <Icon color="primary">loop</Icon>
            </ListItemIcon>
        );
    if (reserveIconSpace)
        return (
            <ListItemIcon className="asset-management-version-release-comparison-dialog-status-icon">
                <div />
            </ListItemIcon>
        );
    return null;
};

export default AssetManagementVersionReleaseComparisonDialogStatusIcon;
