export default class AssetManagementLastRequest {
    private static id: string | undefined;

    static getId(): string | undefined {
        return AssetManagementLastRequest.id;
    }

    static setId(requestId: string | undefined): void {
        AssetManagementLastRequest.id = requestId;
    }
}
