import React, { useRef, useState } from 'react';
import { Collection } from 'types/collection.type';
import ToolIconButton from 'components/ui-components/ToolIconButton';
import Icon from 'components/ui-components-v2/Icon';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import AssetManagementCollectionTileDisplay from './display';
import { useAMCollectionSelection, useAMData, useAMNavigation } from '../../../hooks';
import AMCheckbox from '../../am-checkbox';
import AssetManagementActionsWrapper from '../../actions/actions-wrapper';
import AssetManagementCollectionMenu from '../../collection-menu';
import AssetManagementCollectionDialog from '../../dialogs/collection-dialog';

import '../styles/main.scss';

interface Props {
    collection: Collection;
    collectionTileThumbnails?: React.ReactElement;
}

const AssetManagementCollectionTile = ({ collection, collectionTileThumbnails }: Props) => {
    const { openCollection } = useAMNavigation();
    const { selectCollection } = useAMCollectionSelection(collection.id);
    const { userHasRight } = useAssetManagementConfigContext();
    const {
        data: { selectedCollectionIds }
    } = useAMData<{ selectedCollectionIds: string[] }>({
        selectedCollectionIds: 'state.selectedCollectionIds'
    });
    const [hovered, setHovered] = useState<boolean>(false);
    const [keepMenuMounted, setKeepMenuMounted] = useState(false);
    const [editCollectionDialogOpen, setEditCollectionDialogOpen] = useState<boolean>(false);
    const tileRef = useRef<HTMLDivElement>(null);

    // We should the menu mounted when it's open or a dialog is opened from the menu, even if the mouse leaves the tile.
    const handleKeepMenuMounted = (open: boolean) => {
        setKeepMenuMounted(open);

        // When we close the menu, we want to set the hovered state to false because onMouseLeave isn't called in that scenario.
        if (!open) {
            if (!tileRef.current?.matches(':hover')) {
                setHovered(false);
            }
        }
    };

    const closeEditCollectionDialog = () => {
        setEditCollectionDialogOpen(false);
        if (!tileRef.current?.matches(':hover')) {
            setHovered(false);
        }
    };

    const onTileClick = () => {
        if (selectedCollectionIds.length) {
            selectCollection(collection.id);
            return;
        }

        openCollection(collection.id);
    };

    return (
        <React.Fragment>
            <AssetManagementCollectionTileDisplay
                tileRef={tileRef}
                collection={collection}
                collectionTileThumbnails={collectionTileThumbnails}
                hovered={hovered || keepMenuMounted}
                className="asset-management-selectable-asset"
                onHandleClick={onTileClick}
                onSetHovered={setHovered}>
                <React.Fragment>
                    <AMCheckbox collection={collection} className="asset-management-collection-tile__checkbox" />
                    <AssetManagementActionsWrapper className="asset-management-collection-tile__actions">
                        <ToolIconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                openCollection(collection.id);
                            }}>
                            <Icon>open_in_full</Icon>
                        </ToolIconButton>
                        {userHasRight('management') && (
                            <ToolIconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setEditCollectionDialogOpen(true);
                                }}>
                                <Icon>edit</Icon>
                            </ToolIconButton>
                        )}

                        <AssetManagementCollectionMenu buttonType="toolIcon" collection={collection} onSetKeepMenuMounted={handleKeepMenuMounted} />
                    </AssetManagementActionsWrapper>
                </React.Fragment>
            </AssetManagementCollectionTileDisplay>
            {editCollectionDialogOpen && (
                <AssetManagementCollectionDialog
                    edit
                    activeCollection={collection}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onSetActiveCollection={() => {}} // if the collection is edited all collections are reloaded and this component is remounted.
                    onClose={closeEditCollectionDialog}
                />
            )}
        </React.Fragment>
    );
};

export default AssetManagementCollectionTile;
