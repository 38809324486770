import React from 'react';
import { AssetV2 } from 'types/asset.type';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { useAMFetch, useAMAssetSelection, useAMData, useAMNavigation } from 'components/asset-management/hooks';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onClose: () => void;
    onSetWaiting: (waiting: boolean) => void;
    onSetDialogOpen: (open: boolean) => void;
}

const AssetManagementSetToDraftDialog = ({ asset, onSetDialogOpen, onSetWaiting, onClose }: Props) => {
    const { versioning, languageNameSpace } = useAssetManagementConfigContext();
    const { matchPathToStatus, assetId } = useAMNavigation();
    const { setSelectedAssets } = useAMAssetSelection();
    const { refreshLists } = useAMFetch();
    const { data } = useAMData<{ selectedAssetIds: string[] }>({
        selectedAssetIds: 'state.selectedAssetIds'
    });
    const { selectedAssetIds } = data;

    const setToDraft = async (asset: AssetV2<unknown, unknown>) => {
        onSetWaiting(true);
        const newAsset: AssetV2<unknown, unknown> = { ...asset, status: 'draft' };
        const response = await AssetManagementService.patchAsset(newAsset, { updateOnlyMetadata: true });
        if (response) {
            setSelectedAssets(selectedAssetIds.filter((id: string) => id !== asset._id));
            await refreshLists('available');
            await refreshLists('draft');
            onSetWaiting(false);
            // When the asset is in the detail view, we need to redirect to the correct url for 'draft' status.
            if (assetId) matchPathToStatus('draft');
        }
        onSetDialogOpen(false);
        onClose();
    };

    return (
        <ConfirmDialog
            open
            title={Translation.get('titles.sure', 'common')}
            description={versioning ? Translation.get('dialogs.retract', languageNameSpace) : Translation.get('tooltips.statusDraft', languageNameSpace)}
            onClose={(e) => {
                e.stopPropagation();
                onSetDialogOpen(false);
                onClose();
            }}
            onConfirm={(e) => {
                e.stopPropagation();
                setToDraft(asset);
            }}
        />
    );
};

export default AssetManagementSetToDraftDialog;
