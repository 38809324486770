import React from 'react';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';

interface Props {
    scenario: 'notReleased' | 'formatAdded' | 'firstOptionAdded' | 'formatRemoved' | 'firstOptionRemoved' | 'noFormats' | 'loading' | 'showCreative' | 'none';
}

const TemplateManagementVersionReleaseComparisonDialogText = ({ scenario }: Props) => {
    const { languageNameSpace } = useAssetManagementConfigContext();
    if (scenario === 'loading') return null;
    if (scenario === 'showCreative') return null;
    if (scenario === 'none') return null;

    return (
        <EmptyState
            primaryText={Translation.get(`dialogs.release.emptyStates.${scenario}.title`, languageNameSpace)}
            secondaryText={Translation.get(`dialogs.release.emptyStates.${scenario}.desc`, languageNameSpace)}
            size="small"
        />
    );
};

export default TemplateManagementVersionReleaseComparisonDialogText;
