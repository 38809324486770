import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import Icon from 'components/ui-components-v2/Icon';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onSetReleaseDialogOpen: (open: boolean) => void;
    onClose: () => void;
}

const AssetManagementAssetMenuItemsRelease = ({ asset, onClose, onSetReleaseDialogOpen }: Props) => {
    const { userHasRight } = useAssetManagementConfigContext();

    if (!userHasRight('management')) return null;
    if (asset.status !== 'available') return null;

    const isReleased = asset.version === asset.releasedVersion;

    return (
        <MenuItem
            disabled={isReleased}
            onClick={(e) => {
                e.stopPropagation();
                onSetReleaseDialogOpen(true);
                onClose();
            }}
            data-cy="assetManagement-setDraft-button">
            <ListItemIcon>
                <Icon fontSize="small" color="success">
                    published_with_changes
                </Icon>
            </ListItemIcon>
            <ListItemText slotProps={{ primary: { color: 'success' } }}>
                {isReleased ? Translation.get('labels.releaseUpToDate', 'asset-management') : Translation.get('actions.release', 'common')}
            </ListItemText>
        </MenuItem>
    );
};

export default AssetManagementAssetMenuItemsRelease;
