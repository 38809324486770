import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import Icon from '@mui/material/Icon';
import MenuItem from 'components/ui-components-v2/MenuItem';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import Translation from 'components/data/Translation';
import { canDeletePermanently } from 'components/asset-management/utilities';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onSetAssetToRemove: (permanent: boolean) => void;
    onClose: () => void;
}

const AssetManagementAssetMenuItemsDeletePermanently = ({ asset, onSetAssetToRemove, onClose }: Props) => {
    const { languageNameSpace, userHasRight } = useAssetManagementConfigContext();

    if (!canDeletePermanently(asset, userHasRight)) return null;

    if (asset.status !== 'binned') return null;

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                onSetAssetToRemove(true);
                onClose();
            }}
            data-cy="templateManagement-deletePermanently-button">
            <ListItemIcon>
                <Icon fontSize="small" color="error">
                    delete_forever
                </Icon>
            </ListItemIcon>
            <ListItemText slotProps={{ primary: { color: 'error' } }}>{Translation.get('dialogs.removeAssetPermanent.title', languageNameSpace)}</ListItemText>
        </MenuItem>
    );
};

export default AssetManagementAssetMenuItemsDeletePermanently;
