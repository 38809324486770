import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';

interface Props {
    onSetRemoveCollectionDialogOpen: () => void;
}

const AssetManagementCollectionMenuItemsDelete = ({ onSetRemoveCollectionDialogOpen }: Props) => {
    const { userHasRight } = useAssetManagementConfigContext();

    if (!userHasRight('management')) return null;

    return (
        <>
            <MenuItem onClick={onSetRemoveCollectionDialogOpen} data-cy="assetManagement-collectionMenuItemDelete-button">
                <ListItemIcon>
                    <Icon color="error" fontSize="small">
                        delete
                    </Icon>
                </ListItemIcon>
                <ListItemText slotProps={{ primary: { color: 'error' } }}>{Translation.get('dialogs.removeCollection.title', 'asset-management')}</ListItemText>
            </MenuItem>
        </>
    );
};

export default AssetManagementCollectionMenuItemsDelete;
