import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import Icon from 'components/ui-components-v2/Icon';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onSetToDraftDialogOpen: (open: boolean) => void;
    onClose: () => void;
}

const AssetManagementAssetMenuItemsSetToDraft = ({ asset, onClose, onSetToDraftDialogOpen }: Props) => {
    const { userHasRight } = useAssetManagementConfigContext();

    if (!userHasRight('management')) return null;
    if (asset.status !== 'available') return null;

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                onSetToDraftDialogOpen(true);
                onClose();
            }}
            data-cy="assetManagement-setDraft-button">
            <ListItemIcon>
                <Icon fontSize="small">restore_page</Icon>
            </ListItemIcon>
            <ListItemText>{Translation.get('actions.setToDraft', 'asset-management')}</ListItemText>
        </MenuItem>
    );
};

export default AssetManagementAssetMenuItemsSetToDraft;
