import React, { Dispatch, useMemo, useState } from 'react';
import { Collection, CollectionToCreate } from 'types/collection.type';
import Icon from '@mui/material/Icon';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { get, set } from 'lodash';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from 'components/ui-components-v2/Autocomplete';
import TextField from 'components/ui-components-v2/TextField';
import Radio from 'components/ui-components-v2/Radio';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Translation from 'components/data/Translation';
import Dialog from 'components/ui-components/Dialog';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { useAMFetch, useAMData, useAMNavigation } from 'components/asset-management/hooks/';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { MULTIS } from 'components/asset-management/constants';
import { AssetField, AssetFieldOption } from 'components/asset-management/types/asset-management-field.type';
import { getModelInCollection, canEditPrivacy, formatOptionsWithDepth } from 'components/asset-management/utilities';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import useAMCollectionSelection from 'components/asset-management/hooks/useAMCollectionSelection';

interface BaseProps {
    edit?: boolean;
    activeCollection: Collection | null;
    onClose: () => void;
}

interface EditProps extends BaseProps {
    edit: true;
    onSetActiveCollection: Dispatch<React.SetStateAction<Collection | null>>;
}

interface NonEditProps extends BaseProps {
    edit?: false;
    onSetActiveCollection?: never;
}

type Props = EditProps | NonEditProps;

const AssetManagementCollectionDialog = ({ activeCollection, edit, onSetActiveCollection, onClose }: Props) => {
    const { assetFields, defaultPublic, storeName, type } = useAssetManagementConfigContext();
    const { setSelectedCollections } = useAMCollectionSelection();
    const { fetchCollections, fetchCollectionsTree } = useAMFetch();
    const { data } = useAMData<{ selectedCollectionIds: string[] }>({
        selectedCollectionIds: 'state.selectedCollectionIds'
    });
    const { selectedCollectionIds } = data;
    const { navigateFromRoot } = useAMNavigation();

    // Set the initial collection depending on if we are editing or creating.
    const initialCollection: CollectionToCreate | Collection = useMemo(() => {
        if (edit && activeCollection) {
            return activeCollection as Collection;
        }
        return { title: '', public: defaultPublic, parentId: activeCollection?.id, type } as CollectionToCreate;
    }, [activeCollection, edit]);

    const [collection, setCollection] = useState<CollectionToCreate | Collection>(initialCollection);

    // Get the value to store in the collectio for a autocomplete field.
    const getEnrichedValue = (options: AssetFieldOption[], field: string) => {
        const model = getModelInCollection(field);
        const currentValue = get(collection, model, undefined);
        if (!currentValue) return [];
        return options.filter((o) => currentValue.includes(o.value));
    };

    // Handle the change of a field.
    const handleChange = (field: string, value?: string | boolean) => {
        setCollection((prevCollection) => {
            const model = getModelInCollection(field);
            return set({ ...prevCollection }, model, value);
        });
    };

    // Handle the change of a autocmplete field.
    const handleChangeAutocomplete = (field: AssetField, value: AssetFieldOption[] | null) => {
        setCollection((prevCollection) => {
            const model = getModelInCollection(field.key);
            const derivedValue = (() => {
                if (!value?.length) return undefined;
                const allIncluded = Boolean(value && (value as AssetFieldOption[]).find((v) => v.value === '_all'));
                if (allIncluded && (value as AssetFieldOption[]).length === field.items?.length) return [];
                if (allIncluded) return field.items?.filter((o) => o.value !== '_all').map((v) => v.value);
                return value.map((v) => v.value);
            })();
            return set({ ...prevCollection }, model, derivedValue);
        });
    };

    // Edit the collection on the API and refresh related content in Redux.
    const editCollection = () => {
        if (collection.title?.length) {
            AssetManagementService.patchCollection(collection as Collection).then((response) => {
                if (response) {
                    if (initialCollection.parentId !== response.parentId) {
                        // If we're moving the collection, remove it from the selected collections, since it will be no longer in the current view.
                        setSelectedCollections(selectedCollectionIds.filter((id) => id !== response.id));
                    }
                    fetchCollectionsTree();
                    fetchCollections(true);
                    if (initialCollection.parentId) ComponentStoreHelpers.removeItem(storeName, `collectionsContent.${initialCollection.parentId}`);
                    if (response.parentId) ComponentStoreHelpers.removeItem(storeName, `collectionsContent.${response.parentId}`);
                    if (onSetActiveCollection) onSetActiveCollection(response);
                }
                onClose();
            });
        }
    };

    // Create the collection on the API and refresh related content in Redux.
    const createCollection = () => {
        if (collection.title?.length) {
            AssetManagementService.createCollection(collection).then((response) => {
                if (response) {
                    fetchCollectionsTree(response.id);
                    fetchCollections(true);
                    if (activeCollection?.id) ComponentStoreHelpers.removeItem(storeName, `collectionsContent.${activeCollection.id}`);
                    navigateFromRoot(`/collection/${response.id}`);
                }
                onClose();
            });
        }
    };

    const renderOption = (props, option) => {
        return (
            <li {...props} key={option.value}>
                {option.label}
            </li>
        );
    };

    // Render the option in the dropdown
    const renderOptionMultiple = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: AssetFieldOption,
        selected: boolean,
        field: AssetField,
        value?: AssetFieldOption[]
    ) => {
        const checkedState = (() => {
            if (option.value === '_all') {
                if (field.items?.filter((o) => o.value !== '_all').length === value?.length) {
                    return { checked: true, indeterminate: false };
                } else if (value && value.length > 0) {
                    return { checked: false, indeterminate: true };
                } else {
                    return { checked: false, indeterminate: false };
                }
            } else {
                return { checked: selected, indeterminate: false };
            }
        })();
        return (
            <li {...props} key={option.value}>
                <Checkbox checked={checkedState.checked} indeterminate={checkedState.indeterminate} />
                {option.label}
            </li>
        );
    };

    const collectionField = assetFields.find((field) => field.key === 'collections');
    const parentCollectionValue = collectionField?.items?.find((c) => c.value === collection.parentId) || null;
    const parentCollectionOptions = (() => {
        if (!collectionField?.items) return [];
        if (!activeCollection) return collectionField.items;
        return collectionField.items.map((option) => {
            if (option.value === activeCollection.id) return { ...option, disabled: true };
            return option;
        });
    })();

    return (
        <Dialog
            title={edit ? Translation.get('actions.editCollection', 'asset-management') : Translation.get('actions.createCollection', 'asset-management')}
            open
            onClose={onClose}
            onConfirm={edit ? editCollection : createCollection}
            confirmText={edit ? Translation.get('actions.edit', 'common') : Translation.get('actions.create', 'common')}
            confirmButtonDisabled={!collection.title?.length}
            onConfirmButtonDataCy={'assetManagement-confirmDialog-button'}
            confirmIcon={<Icon>{edit ? 'edit' : 'add'}</Icon>}
            contentPadding
            fullWidth
            maxWidth="sm">
            <FormControl fullWidth margin="dense">
                <TextField
                    value={collection.title}
                    onChange={(e) => handleChange('title', e.target.value)}
                    label={Translation.get('labels.collectionName', 'asset-management')}
                    data-cy="assetManagement-collectionName-input"
                    required
                />
            </FormControl>
            {edit && collectionField && (
                <FormControl fullWidth margin="dense">
                    <Autocomplete
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        options={formatOptionsWithDepth(parentCollectionOptions)}
                        getOptionLabel={(option) => option.label}
                        getOptionDisabled={(option) => option.disabled}
                        value={parentCollectionValue}
                        onChange={(_e, value) => handleChange('parentId', value?.value)}
                        renderOption={(props, option) => renderOption(props, option)}
                        data-cy="assetManagement-parentCollection-input"
                        renderInput={(params) => <TextField {...params} variant="outlined" label={Translation.get('parentCollection', 'asset-management')} />}
                    />
                </FormControl>
            )}
            {canEditPrivacy(assetFields) && (
                <FormControl margin="dense">
                    <RadioGroup
                        value={collection.public}
                        name="public"
                        onChange={(e) => handleChange('public', e.target.value === 'true')}
                        data-cy="assetManagement-privacy-input">
                        <FormControlLabel value={true} control={<Radio />} label={Translation.get('labels.public', 'asset-management')} />
                        <FormControlLabel value={false} control={<Radio />} label={Translation.get('labels.private', 'asset-management')} />
                    </RadioGroup>
                    <FormHelperText>{Translation.get('helpertext.publicCollection', 'asset-management')}</FormHelperText>
                </FormControl>
            )}
            {Object.keys(MULTIS).map((name) => {
                const field = assetFields.find((field) => field.key === name);
                if (field && field.items?.length) {
                    const label = Translation.get('actions.restrictCollectionTo', 'asset-management', { field: name });
                    const enrichedValue = getEnrichedValue(field.items, name);
                    return (
                        <FormControl fullWidth margin="dense" key={`select-${name}`}>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                options={field.items}
                                getOptionLabel={(option) => option.label}
                                value={enrichedValue}
                                onChange={(_e, value) => handleChangeAutocomplete(field, value)}
                                data-cy={`assetManagement-${name}-input`}
                                renderOption={(props, option, { selected }) => renderOptionMultiple(props, option, selected, field, enrichedValue)}
                                renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
                            />
                            <FormHelperText>{Translation.get(`helpertext.specialTypes.${name}`, 'asset-management')}</FormHelperText>
                        </FormControl>
                    );
                }
            })}
        </Dialog>
    );
};

export default AssetManagementCollectionDialog;
