import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import Typography from 'components/ui-components-v2/Typography';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import {
    FormatOption,
    FrameTypeOption,
    SubSetOption
} from 'components/asset-management/components/version-release-comparison-dialog/types/asset-management-version-comparison-options.type';
import AssetManagementVersionReleaseComparisonDialogStatusIcon from './status-icon';

import '../styles/option.scss';

interface Props {
    option: FrameTypeOption | FormatOption | SubSetOption;
    extended?: boolean;
    isReleased: boolean;
    reserveIconSpace: boolean;
}

const AssetManagementVersionReleaseComparisonDialogOption = ({ option, isReleased, reserveIconSpace, extended }: Props) => {
    const { languageNameSpace } = useAssetManagementConfigContext();
    const extendedText = (() => {
        if (!extended) return '';
        if (!isReleased) return '';
        if (option.latest && !option.released) return Translation.get(`dialogs.release.options.${option.optionType}.added`, languageNameSpace);
        if (!option.latest && option.released) return Translation.get(`dialogs.release.options.${option.optionType}.removed`, languageNameSpace);
        if (option.latest && option.released && option.interfaceChanged)
            return Translation.get(`dialogs.release.options.${option.optionType}.interfaceChanged`, languageNameSpace);
        if (option.latest && option.released && option.changed)
            return Translation.get(`dialogs.release.options.${option.optionType}.changed`, languageNameSpace);
        return '';
    })();

    return (
        <>
            <AssetManagementVersionReleaseComparisonDialogStatusIcon option={option} reserveIconSpace={reserveIconSpace} />
            <ListItemText primary={option.title} />
            {extended && (
                <Typography variant="caption" className="asset-management-version-release-comparison-dialog-option__extended-text">
                    {extendedText}
                </Typography>
            )}
        </>
    );
};

export default AssetManagementVersionReleaseComparisonDialogOption;
