import React from 'react';
import { FilterSetup, Filters } from 'types/filter.type';
import GenericFilter from 'components/ui-base/GenericFilter';
import { AssetManagementSortDropdownDisplay } from 'components/asset-management/components/sort-dropdown';
import { SortDirection, SortField } from 'components/asset-management/types/asset-management.service.type';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import '../styles/filter-bar.scss';

interface Props {
    filterSetup: FilterSetup;
    filters: Filters;
    hideFilters: string[];
    sortField?: SortField;
    sortDirection?: SortDirection;
    forceSearchTerm?: string;
    onChangeFilter: (key: string, value: string | string[]) => void;
    onClearFilter: () => void;
    onSort: (sortField: SortField, sortDirection: SortDirection) => void;
}

const AssetManagementSelectorFilterBar = ({
    filterSetup,
    filters,
    hideFilters,
    sortField,
    sortDirection,
    forceSearchTerm,
    onChangeFilter,
    onClearFilter,
    onSort
}: Props) => {
    const { languageNameSpace } = useAssetManagementConfigContext();
    const changeQuery = (value: string) => {
        onChangeFilter('query', value);
    };

    return (
        <GenericFilter
            inDialog
            searchField
            searchPlaceholder={Translation.get('labels.search', languageNameSpace)}
            filterSetup={filterSetup}
            filters={filters}
            forceSearchTerm={forceSearchTerm}
            hideChips
            ignoreInFiltersCount={hideFilters}
            onSearch={changeQuery}
            onChangeFilter={onChangeFilter}
            onClearAll={onClearFilter}
            className="asset-management-selector-filter-bar"
            dataCyPrefix="assetManagementSelector">
            <AssetManagementSortDropdownDisplay sortField={sortField} sortDirection={sortDirection} onSort={onSort} />
        </GenericFilter>
    );
};

export default AssetManagementSelectorFilterBar;
