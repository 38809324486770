import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import Icon from '@mui/material/Icon';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { canDelete } from 'components/asset-management/utilities';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onSetAssetToRemove: (permanent: boolean) => void;
    onClose: () => void;
}

const AssetManagementAssetMenuItemsMoveToBin = ({ asset, onSetAssetToRemove, onClose }: Props) => {
    const { languageNameSpace, userHasRight } = useAssetManagementConfigContext();

    if (!canDelete(asset, userHasRight)) return null; // Only show the bin option if the user can delete the asset.

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                onSetAssetToRemove(false);
                onClose();
            }}
            data-cy="assetManagement-moveToBin-button">
            <ListItemIcon>
                <Icon fontSize="small" color="error">
                    delete
                </Icon>
            </ListItemIcon>
            <ListItemText slotProps={{ primary: { color: 'error' } }}>{Translation.get('dialogs.removeAsset.title', languageNameSpace)}</ListItemText>
        </MenuItem>
    );
};

export default AssetManagementAssetMenuItemsMoveToBin;
