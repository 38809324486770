import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import Icon from 'components/ui-components-v2/Icon';

interface Props {
    onSetCreateCollectionDialogOpen: () => void;
}

const AssetManagementCollectionMenuItemsCreateSubColl = ({ onSetCreateCollectionDialogOpen }: Props) => {
    const { userHasRight } = useAssetManagementConfigContext();

    if (!userHasRight('management')) return null;

    return (
        <>
            <MenuItem onClick={onSetCreateCollectionDialogOpen}>
                <ListItemIcon>
                    <Icon fontSize="small">create_new_folder</Icon>
                </ListItemIcon>
                <ListItemText>{Translation.get('actions.createSubCollection', 'asset-management')}</ListItemText>
            </MenuItem>
        </>
    );
};

export default AssetManagementCollectionMenuItemsCreateSubColl;
