import React, { useMemo } from 'react';
import { AssetV2 } from 'types/asset.type';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useAMAssetsNotValidated, useAMNavigation, useAMRelease } from 'components/asset-management/hooks';
import Dialog from 'components/ui-components/Dialog';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import Icon from 'components/ui-components-v2/Icon';
import useEditorChanged from 'components/editor-data/EditorData/hooks/useEditorChanged';

interface Props {
    latestAsset: AssetV2<unknown, unknown>;
    onSetFreshAsset?: (asset: AssetV2<unknown, unknown>) => void;
    onClose: () => void;
}

const AssetManagementVersionReleaseComparisonDialogConfirmNoCompare = ({ latestAsset, onSetFreshAsset, onClose }: Props) => {
    const changed = useEditorChanged();
    const { releaseAsset, waiting } = useAMRelease(latestAsset, onClose, onSetFreshAsset);
    const { languageNameSpace, userHasRight } = useAssetManagementConfigContext();
    const { statusInView } = useAMNavigation();
    const assetsNotValidated = useAMAssetsNotValidated([latestAsset], statusInView);

    const text = (() => {
        if (latestAsset.releasedVersion === latestAsset.version && !changed)
            return Translation.get('dialogs.releaseNoCompare.desc.latestReleased', languageNameSpace, { latest: latestAsset.version || 0 });
        if (latestAsset.releasedVersion)
            return `${Translation.get('dialogs.releaseNoCompare.desc.releasedBefore', languageNameSpace, {
                latest: latestAsset.version || 0,
                released: latestAsset.releasedVersion || 0
            })} ${changed ? Translation.get('dialogs.releaseNoCompare.desc.unsavedChanges', languageNameSpace) : ''}`;
        return Translation.get('dialogs.releaseNoCompare.desc.notReleased', languageNameSpace, { latest: latestAsset.version || 0 });
    })();

    const disabled = useMemo(() => {
        if (waiting) return true; // Disable if the release is in progress
        if (Object.keys(assetsNotValidated).length) return true; // Disable if the asset is not validated
        if (changed) return false; // Enable if the asset has changes
        if (latestAsset.releasedVersion === latestAsset.version) return true; // Disable if the latest asset version is already released
        return false;
    }, [waiting, assetsNotValidated]);

    if (latestAsset.status !== 'available') {
        // We can only compare released, and therefore available assets.
        return null;
    }

    return (
        <Dialog
            title={Translation.get('dialogs.releaseNoCompare.title', languageNameSpace)}
            confirmText={Translation.get('dialogs.release.confirmBtn', languageNameSpace)}
            confirmIcon={<Icon>published_with_changes</Icon>}
            confirmColor="success"
            confirmWaiting={waiting}
            open
            showCancel
            confirmButtonDisabled={disabled}
            maxWidth="md"
            onConfirm={userHasRight('management') && releaseAsset}
            onClose={onClose}>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default AssetManagementVersionReleaseComparisonDialogConfirmNoCompare;
