import React from 'react';
import { AssetV2 } from 'types/asset.type';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import Translation from 'components/data/Translation';
import { useAMFetch, useAMData, useAMNavigation } from 'components/asset-management/hooks';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { AssetListState } from 'components/asset-management/types/asset-management-store.type';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import useAMAssetSelection from 'components/asset-management/hooks/useAMAssetSelection';

interface Props {
    asset: AssetV2<unknown, unknown>;
    permanent?: boolean;
    onClose: () => void;
}

interface Data {
    assetsList: AssetV2<unknown, unknown>[] | null;
    binned: AssetListState | null;
    draft: AssetListState | null;
    selectedAssetIds: string[];
}

const AssetManagementRemoveAssetDialog = ({ asset, permanent, onClose }: Props) => {
    const { assetId, navigate, removeParamsFromPath, matchPathToStatus } = useAMNavigation();
    const { setSelectedAssets } = useAMAssetSelection();
    const { languageNameSpace, storeName } = useAssetManagementConfigContext();
    const { refreshLists } = useAMFetch();
    const { data } = useAMData<Data>({
        assetsList: 'assetsList',
        binned: 'binned',
        draft: 'draft',
        selectedAssetIds: 'state.selectedAssetIds'
    });
    const { assetsList, binned, draft, selectedAssetIds } = data;

    const removeAsset = async () => {
        await AssetManagementService.deleteAsset(asset._id, permanent);

        // If the file was selected, remove it from the selected list.
        setSelectedAssets(selectedAssetIds.filter((id: string) => id !== asset._id));

        // Reload lists that could contain this asset.
        if (asset.status === 'available' && assetsList) await refreshLists('available');
        if ((!permanent || asset.status === 'binned') && binned) await refreshLists('binned');
        if (asset.status === 'draft' && draft) await refreshLists('draft');

        // In case this asset is part of one or more collections, remove the content of those collections from the Redux store.
        if (asset.status === 'available' && asset.collections?.length) {
            asset.collections.forEach((collectionId) => {
                ComponentStoreHelpers.removeItem(storeName, `collectionsContent.${collectionId}`);
            });
        }
        // If we are in detail view send to the right path after asset has been removed.
        if (assetId) {
            if (permanent) {
                navigate(removeParamsFromPath([assetId]));
            } else {
                matchPathToStatus('binned');
            }
        }
        onClose();
    };

    return (
        <ConfirmDialog
            onConfirm={removeAsset}
            confirmColor={'error'}
            confirmText={permanent ? Translation.get('actions.removePermanent', 'common') : Translation.get('actions.bin', 'common')}
            onClose={onClose}
            open
            title={
                permanent
                    ? Translation.get('dialogs.removeAssetPermanent.title', languageNameSpace)
                    : Translation.get('dialogs.removeAsset.title', languageNameSpace)
            }
            description={
                permanent
                    ? Translation.get('dialogs.removeAssetPermanent.desc', languageNameSpace, { title: asset.title })
                    : Translation.get('dialogs.removeAsset.desc', languageNameSpace, { title: asset.title })
            }
        />
    );
};

export default AssetManagementRemoveAssetDialog;
