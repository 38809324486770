import React, { useMemo, useRef } from 'react';
import { AssetV2 } from 'types/asset.type';
import { useComponentSize } from 'hooks/useComponentSize';
import Dialog from 'components/ui-components/Dialog';
import Select from 'components/ui-components-v2/Select';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import MenuItem from 'components/ui-components-v2/MenuItem';
import { useAMAssetsNotValidated, useAMNavigation, useAMRelease } from 'components/asset-management/hooks';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import DynamicAssetV2 from 'components/creatives-v2/components/dynamic-asset-v2';
import Loader from 'components/ui-components/Loader';
import Typography from 'components/ui-components-v2/Typography';
import { CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import Icon from 'components/ui-components-v2/Icon';
import { TemplateCreativeAsset } from 'components/creative-management-v2/types/creative-management.type';
import useEditorChanged from 'components/editor-data/EditorData/hooks/useEditorChanged';
import AssetManagementVersionReleaseComparisonDialogText from './text';
import AssetManagementVersionReleaseComparisonDialogOption from './option';
import { FormatOptions, FrameTypeOptions, SubSetOptions } from '../types/asset-management-version-comparison-options.type';

import '../styles/main.scss';

interface Props {
    latestAsset: TDTemplateAsset | TemplateCreativeAsset;
    releasedAsset: TDTemplateAsset | TemplateCreativeAsset | null;
    latestCreative: CreativeV2Template | null;
    releasedCreative: CreativeV2Template | null;
    loaded: boolean;
    firstOptions: FrameTypeOptions | SubSetOptions | null;
    activeFirstOptionsKey: string | null;
    hideFirstOptions?: boolean;
    formatOptions: FormatOptions | null;
    activeFormatKey: string | null;
    handleFrameTypeKeyChange: (key: string) => void;
    handleFormatKeyChange: (key: string) => void;
    onSetFreshAsset?: (asset: AssetV2<unknown, unknown>) => void;
    onClose: () => void;
}

const selectListHasIcons = (options: FrameTypeOptions | SubSetOptions | FormatOptions, released: boolean): boolean => {
    const optionHasIcon = (option) => {
        if (option.latest && !option.released) return true;
        if (!option.latest && option.released) return true;
        if (option.latest && option.released && option.interfaceChanged) return true;
        if (option.latest && option.released && option.changed) return true;
        return false;
    };

    if (!released) return false;
    return Object.values(options).some(optionHasIcon);
};

const CONTAINER_MARGIN = 32;

const AssetManagementVersionReleaseComparisonDialog = ({
    latestAsset,
    releasedAsset,
    latestCreative,
    releasedCreative,
    loaded,
    firstOptions,
    activeFirstOptionsKey,
    hideFirstOptions,
    formatOptions,
    activeFormatKey,
    handleFrameTypeKeyChange,
    handleFormatKeyChange,
    onSetFreshAsset,
    onClose
}: Props) => {
    const changed = useEditorChanged();
    const { waiting, releaseAsset } = useAMRelease(latestAsset, onClose, onSetFreshAsset);
    const { languageNameSpace, userHasRight } = useAssetManagementConfigContext();
    const { statusInView } = useAMNavigation();
    const assetsNotValidated = useAMAssetsNotValidated([latestAsset], statusInView);
    const containerRef = useRef<HTMLDivElement>(null);
    const { height: containerHeight, width: containerWidth } = useComponentSize(containerRef);

    const scale = useMemo(() => {
        if (!formatOptions) return 1;
        if (!activeFormatKey) return 1;
        if (!containerHeight) return 1;
        const { width: formatWidth, height: formatHeight } = formatOptions[activeFormatKey];
        const widthScale = (containerWidth - CONTAINER_MARGIN) / formatWidth;
        const heightScale = (containerHeight - CONTAINER_MARGIN) / formatHeight;
        return Math.min(1, widthScale, heightScale);
    }, [activeFormatKey, formatOptions, containerHeight]);

    const disabled = useMemo(() => {
        if (latestAsset.releasedVersion === latestAsset.version && !changed) return true; // Disable if the latest asset version is already released and there are no unsaved changes
        if (waiting) return true; // Disable if the release is in progress
        if (Object.keys(assetsNotValidated).length) return true; // Disable if the asset is not validated
        return false;
    }, [waiting, assetsNotValidated]);

    const firstOptionsHasIcons = useMemo(
        () => (firstOptions ? selectListHasIcons(firstOptions, Boolean(releasedAsset)) : false),
        [firstOptions, releasedAsset]
    );

    const formatOptionsHasIcons = useMemo(
        () => (formatOptions ? selectListHasIcons(formatOptions, Boolean(releasedAsset)) : false),
        [formatOptions, releasedAsset]
    );

    const optionsReady = firstOptions !== null && formatOptions !== null;

    const scaleText = `${Translation.get('labels.scaled', 'common')} ${(scale * 100).toFixed()}%`;

    // Find the scenario of what t show for the released asset
    const releasedScenario = (() => {
        if (!loaded) return 'loading'; // Asset is still loading
        if (!optionsReady) return 'loading'; // Options are not calculated yet.
        if (!releasedAsset) return 'notReleased'; // There is no released asset.
        if (Object.values(formatOptions).filter((format) => format.released).length === 0) return 'noFormats'; // This version has no formats
        if (releasedCreative) {
            if (activeFormatKey && !formatOptions[activeFormatKey].released) return 'formatAdded';
            if (activeFirstOptionsKey && !firstOptions[activeFirstOptionsKey].released) return 'firstOptionAdded';
            return 'showCreative';
        }
        return 'none';
    })();

    // Find the scenario of what to show for the latest asset
    const latestScenario = (() => {
        if (!optionsReady) return 'loading'; // Options are not calculated yet.
        if (Object.values(formatOptions).filter((format) => format.latest).length === 0) return 'noFormats'; // This version has no formats
        if (latestCreative) {
            if (activeFirstOptionsKey && !firstOptions?.[activeFirstOptionsKey]?.latest) return 'firstOptionRemoved';
            if (activeFormatKey && !formatOptions[activeFormatKey].latest) return 'formatRemoved';
            return 'showCreative';
        }
        return 'none';
    })();

    if (latestAsset.status !== 'available') {
        // We can only compare released, and therefore available assets.
        return null;
    }

    return (
        <Dialog
            fixedHeight
            onConfirm={userHasRight('management') && releaseAsset}
            confirmText={Translation.get('dialogs.release.confirmBtn', languageNameSpace)}
            confirmIcon={<Icon>published_with_changes</Icon>}
            confirmColor="success"
            confirmWaiting={waiting}
            showCancel
            confirmButtonDisabled={disabled}
            onConfirmButtonDataCy="assetManagement-releaseDialogConfirm-button"
            title={Translation.get('dialogs.release.title', languageNameSpace)}
            classes={{ content: 'asset-management-version-release-comparison-dialog' }}
            onClose={onClose}
            open>
            <React.Fragment>
                {optionsReady && activeFirstOptionsKey && (
                    <div className="asset-management-version-release-comparison-dialog__header">
                        {!hideFirstOptions && (
                            <Select
                                value={activeFirstOptionsKey}
                                margin="dense"
                                size="small"
                                onChange={(e) => handleFrameTypeKeyChange(e.target.value as string)}
                                renderValue={(value) => (
                                    <div className="asset-management-version-release-comparison-dialog__value">
                                        <AssetManagementVersionReleaseComparisonDialogOption
                                            option={firstOptions[value as string]}
                                            reserveIconSpace={false}
                                            isReleased={Boolean(releasedAsset)}
                                        />
                                    </div>
                                )}>
                                {Object.entries(firstOptions).map(([key, frameType]) => (
                                    <MenuItem key={key} value={key} selected={activeFirstOptionsKey === key}>
                                        <AssetManagementVersionReleaseComparisonDialogOption
                                            option={frameType}
                                            reserveIconSpace={firstOptionsHasIcons}
                                            isReleased={Boolean(releasedAsset)}
                                            extended
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        {formatOptions && activeFormatKey && (
                            <Select
                                value={activeFormatKey}
                                margin="dense"
                                size="small"
                                onChange={(e) => handleFormatKeyChange(e.target.value as string)}
                                renderValue={(value) => (
                                    <div className="asset-management-version-release-comparison-dialog__value">
                                        <AssetManagementVersionReleaseComparisonDialogOption
                                            option={formatOptions[value as string]}
                                            reserveIconSpace={false}
                                            isReleased={Boolean(releasedAsset)}
                                        />
                                    </div>
                                )}>
                                {Object.entries(formatOptions).map(([key, format]) => (
                                    <MenuItem key={key} value={key} selected={activeFormatKey === key}>
                                        <AssetManagementVersionReleaseComparisonDialogOption
                                            option={format}
                                            reserveIconSpace={formatOptionsHasIcons}
                                            isReleased={Boolean(releasedAsset)}
                                            extended
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </div>
                )}
                <div className="asset-management-version-release-comparison-dialog__content">
                    <div className="asset-management-version-release-comparison-dialog__column">
                        <Loader isLoading={releasedScenario === 'loading'}>
                            <div className="asset-management-version-release-comparison-dialog__caption">
                                <Typography variant="caption">
                                    {Translation.get('labels.releasedVersion', 'asset-management')}: {releasedAsset?.version || '-'}
                                </Typography>
                                {releasedAsset?.version && (
                                    <Typography variant="caption" color="textSecondary">
                                        {scaleText}
                                    </Typography>
                                )}
                            </div>
                            <div className="asset-management-version-release-comparison-dialog__preview">
                                {releasedScenario === 'showCreative' && (
                                    <DynamicAssetV2
                                        key={`releasedAsset_${activeFirstOptionsKey}_${activeFormatKey}`}
                                        scale={scale}
                                        creative={releasedCreative as CreativeV2Template}
                                        formatKey={activeFormatKey || undefined}
                                        iframeOverwrites={{
                                            data: {
                                                showPlaybar: true,
                                                editmode: false
                                            }
                                        }}
                                    />
                                )}
                                <AssetManagementVersionReleaseComparisonDialogText scenario={releasedScenario} />
                            </div>
                        </Loader>
                    </div>
                    <div className="asset-management-version-release-comparison-dialog__column">
                        <div className="asset-management-version-release-comparison-dialog__caption">
                            <Typography variant="caption">
                                {Translation.get('labels.latestVersion', 'asset-management')}: {latestAsset.version}
                                {changed && ` (${Translation.get('labels.withChanges', 'common')})`}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                {scaleText}
                            </Typography>
                        </div>
                        <div ref={containerRef} className="asset-management-version-release-comparison-dialog__preview">
                            {latestScenario === 'showCreative' && (
                                <DynamicAssetV2
                                    key={`latestAsset_${activeFirstOptionsKey}_${activeFormatKey}`}
                                    scale={scale}
                                    creative={latestCreative as CreativeV2Template}
                                    formatKey={activeFormatKey || undefined}
                                    iframeOverwrites={{
                                        data: {
                                            showPlaybar: true,
                                            editmode: false
                                        }
                                    }}
                                />
                            )}
                            <AssetManagementVersionReleaseComparisonDialogText scenario={latestScenario} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </Dialog>
    );
};

export default AssetManagementVersionReleaseComparisonDialog;
