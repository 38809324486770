import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { set } from 'lodash';
import { AssetType, AssetV2 } from 'types/asset.type';
import { FilterSetup, Filters } from 'types/filter.type';
import { useInCampaign } from 'hooks/useInCampaign';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import {
    AssetListPayload,
    AssetListResponse,
    CollectionListPayload,
    FetchPayload,
    SortDirection,
    SortField
} from 'components/asset-management/types/asset-management.service.type';
import {
    applyPreFilters,
    getAssetListStateFromResponse,
    getChangedFilter,
    getCollectionOptionsFromTree,
    getCurrentFilterSetup,
    getListParams,
    transformAssetParamsForRootKeys
} from 'components/asset-management/utilities';
import Translation from 'components/data/Translation';
import { AssetListState, CollectionListState } from 'components/asset-management/types/asset-management-store.type';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import EditorData from 'components/editor-data/EditorData';
import { SPECIAL_FIELDS } from 'components/asset-management/constants';
import { SimpleCollection } from '../types/simple-collection.type';
import AssetManagementSelectorAssets from './assets';
import AssetManagementSelectorCollections from './collections';
import AssetManagementSelectorCollection from './collection';
import AssetManagementSelectorTabs from './tabs';

import '../styles/main.scss';

interface Props {
    dataFilters?: {
        [key: string]: string | string[] | boolean;
    };
    preFilters?: { [key: string]: string[] };
    addedContent?: ReactElement;
    collectionQuery?: string;
    type: AssetType;
    assetTile: ReactElement;
    hideTabs?: boolean;
    selectedItemLoading?: boolean;
    getAssetsAlternative?: (params: AssetListPayload, queryParams?: { isVerbose?: boolean }) => Promise<AssetListResponse>;
    onSelect: (item: AssetV2<unknown, unknown>) => void;
}

type CollectionListStateComplete = CollectionListState & {
    collectionFilters: Filters;
    collectionSortField?: SortField;
    collectionSortDirection?: SortDirection;
};

const ASSET_STATUS = 'available';
const CATEGORIES_KEY = 'categories';

const AssetManagementSelector = ({
    dataFilters,
    preFilters,
    addedContent,
    collectionQuery,
    assetTile,
    type,
    hideTabs,
    selectedItemLoading,
    getAssetsAlternative,
    onSelect
}: Props) => {
    const inCampaign = useInCampaign();
    const { assetFilterSetup, collectionsTree, collectionFilterSetup, versioning, languageNameSpace, fullDataInList } = useAssetManagementConfigContext();
    const [tab, setTab] = useState<'assets' | 'collections'>('assets');
    const [categories, setCategories] = useState<string[] | null>(null);
    const [listState, setListState] = useState<AssetListState>({
        assetsList: null,
        assetsNextPageToken: 0,
        assetsLoading: true,
        assetsCounts: {},
        assetFilters: {},
        assetSortField: 'updatedAt',
        assetSortDirection: 'desc',
        totalAssetsCount: 0,
        filteredAssetsCount: 0
    });
    const [collectionsListState, setCollectionsListState] = useState<CollectionListStateComplete>({
        collectionsList: null,
        collectionsNextPageToken: 0,
        collectionFilters: {},
        collectionSortField: undefined,
        collectionSortDirection: undefined
    });
    const [openCollection, setOpenCollection] = useState<SimpleCollection | null>(null);
    const [currentFilterSetup, setCurrentFilterSetup] = useState<FilterSetup>(assetFilterSetup);
    const [collectionsLoading, setCollectionsLoading] = useState(true);
    const allCollections = useMemo(() => getCollectionOptionsFromTree(collectionsTree), [collectionsTree]);
    const hideFilters = [CATEGORIES_KEY];
    const lastRequestedPage = useRef<number>(0);
    const lastRequestedCollectionsPage = useRef<number>(0);

    useEffect(() => {
        if (!listState.assetsList) {
            getAssetsContent(true);
        }
        if (collectionQuery && typeof collectionQuery === 'string') {
            const foundCollection = allCollections.find((col) => col.label.toLowerCase().includes(collectionQuery.toLowerCase())) || null;
            setOpenCollection(foundCollection ? { id: foundCollection.value, title: foundCollection.label } : null);
        }
    }, []);

    const getAssetsContent = (refresh: boolean, payload?: FetchPayload) => {
        const shouldRefresh = Boolean(refresh || payload);

        if (shouldRefresh) lastRequestedPage.current = 0;

        // Do not wait for the API to set the choosen filters.
        setListState((prevListState: AssetListState) => {
            return { ...prevListState, assetFilters: payload?.filters || {} };
        });

        const params: AssetListPayload = getListParams(
            type,
            {
                stateList: listState.assetsList,
                stateNextPageToken: listState.assetsNextPageToken,
                stateFilters: listState.assetFilters,
                stateSortField: listState.assetSortField,
                stateSortDirection: listState.assetSortDirection
            },
            ASSET_STATUS,
            shouldRefresh,
            payload
        );

        if (inCampaign) {
            // When we're editing a campaign or campaign concept, we might need to filter on the special fields tied to that campain.
            Object.entries(SPECIAL_FIELDS).forEach(([key, value]) => {
                const campaignValue = EditorData.get(key);
                if (campaignValue?.length) {
                    hideFilters.push(value);
                    set(params, `filters.${value}`, [campaignValue, '_unassigned']);
                }
            });
        }

        if (dataFilters && Object.keys(dataFilters).length) set(params, 'dataFilters', dataFilters);
        const transformedAssetsParams = transformAssetParamsForRootKeys(params);

        // Make sure a request for this page isn't allready in progress.
        if (transformedAssetsParams.nextPageToken === lastRequestedPage.current) return;
        lastRequestedPage.current = transformedAssetsParams.nextPageToken || 0;

        // We might have an alternative endpoint to fetch assets from. e.g. the Cape library for templates.
        const getAssets = (() => {
            if (getAssetsAlternative)
                return async (transformedAssetsParams: AssetListPayload) =>
                    await getAssetsAlternative({
                        filterExpired: true,
                        filterReleased: Boolean(versioning),
                        ...transformedAssetsParams
                    });
            return async (transformedAssetsParams: AssetListPayload) =>
                await AssetManagementService.getAssetBatch(
                    {
                        filterExpired: true,
                        filterReleased: Boolean(versioning),
                        ...transformedAssetsParams
                    },
                    {
                        isVerbose: fullDataInList
                    }
                );
        })();

        if (preFilters && Object.keys(preFilters).length) set(transformedAssetsParams, 'filters', applyPreFilters(transformedAssetsParams.filters, preFilters));
        getAssets(transformedAssetsParams).then((response) => {
            setListState((prevListState: AssetListState) => {
                const newListState = getAssetListStateFromResponse(response, prevListState.assetsList || [], shouldRefresh, params);
                return { ...prevListState, ...newListState };
            });
            if (!categories && response.counts[CATEGORIES_KEY]) {
                setCategories(Object.keys(response.counts[CATEGORIES_KEY]).filter((category) => category !== '_unassigned')); // Set categories for display if categories where found in the first response
            }
            setCurrentFilterSetup(getCurrentFilterSetup(assetFilterSetup, response.counts, params.filters || {}, hideFilters, preFilters));
        });
    };

    const changeAssetsFilter = (key: string, value: string | string[]) => {
        const filters = getChangedFilter(listState.assetFilters, key, value);
        getAssetsContent(true, { filters });
    };

    const clearAssetsFilter = () => {
        getAssetsContent(true, { filters: {} });
    };

    const getCollectionsContent = (refresh: boolean, payload?: FetchPayload) => {
        const shouldRefresh = Boolean(refresh || payload);
        setCollectionsLoading(true);

        if (shouldRefresh) lastRequestedCollectionsPage.current = 0;

        const params: CollectionListPayload = getListParams(
            type,
            {
                stateList: collectionsListState.collectionsList,
                stateNextPageToken: collectionsListState.collectionsNextPageToken,
                stateFilters: collectionsListState.collectionFilters,
                stateSortField: collectionsListState.collectionSortField,
                stateSortDirection: collectionsListState.collectionSortDirection
            },
            ASSET_STATUS,
            shouldRefresh,
            payload
        );

        // Make sure a request for this page isn't allready in progress.
        if (params.nextPageToken === lastRequestedCollectionsPage.current) return;
        lastRequestedCollectionsPage.current = params.nextPageToken || 0;

        AssetManagementService.getCollectionBatch({ filterExpired: true, filterReleased: Boolean(versioning), ...params }).then((response) => {
            const { collections, nextPageToken } = response;
            setCollectionsListState((prevListState: CollectionListStateComplete) => {
                return {
                    collectionsList: shouldRefresh ? response.collections : [...(prevListState.collectionsList || []), ...collections],
                    collectionsNextPageToken: nextPageToken,
                    collectionFilters: params.filters || {},
                    collectionSortField: params.sortField,
                    collectionSortDirection: params.sortDirection
                };
            });
            setCollectionsLoading(false);
        });
    };

    const changeCollectionsFilter = (key: string, value: string | string[]) => {
        const filters = getChangedFilter(collectionsListState.collectionFilters, key, value);
        getCollectionsContent(true, { filters });
    };

    const clearCollectionsFilter = () => {
        getCollectionsContent(true, { filters: {} });
    };

    const changeTab = (tab: 'assets' | 'collections') => {
        setTab(tab);
        if (tab === 'collections' && !collectionsListState.collectionsList) {
            getCollectionsContent(true);
        }
    };

    const selectCollectionById = (collectionId?: string) => {
        const reset = () => {
            setOpenCollection(null);
            changeTab('collections');
        };

        if (!collectionId) {
            reset();
            return;
        }

        const foundCollection = allCollections.find((col) => col.value === collectionId) || null;
        if (!foundCollection) {
            reset();
            return;
        }

        setOpenCollection({ id: foundCollection.value, title: foundCollection.label });
    };

    return (
        <div className="asset-management-selector">
            {!openCollection && (
                <React.Fragment>
                    {!hideTabs && (
                        <AssetManagementSelectorTabs
                            tabs={[
                                {
                                    label:
                                        preFilters && 'subType' in preFilters && preFilters.subType.length === 1
                                            ? Translation.get(`sub_types.${preFilters.subType[0]}`, languageNameSpace, { count: 2 })
                                            : Translation.get('asset_other', languageNameSpace),
                                    value: 'assets'
                                },
                                {
                                    label: Translation.get('labels.collections', 'asset-management'),
                                    value: 'collections'
                                }
                            ]}
                            activeTab={tab}
                            className="asset-management-selector__navigation"
                            onChange={changeTab as (value: string) => void}
                        />
                    )}
                    {tab === 'assets' && (
                        <AssetManagementSelectorAssets
                            assetsList={listState.assetsList}
                            categories={categories}
                            addedContent={addedContent}
                            hideFilters={hideFilters}
                            hasMore={Boolean(listState.assetsNextPageToken)}
                            filters={listState.assetFilters}
                            filterSetup={currentFilterSetup}
                            sortField={listState.assetSortField}
                            sortDirection={listState.assetSortDirection}
                            loading={listState.assetsLoading}
                            selectedItemLoading={Boolean(selectedItemLoading)}
                            assetTile={assetTile}
                            onSelect={onSelect}
                            onChangeFilter={changeAssetsFilter}
                            onClearFilter={clearAssetsFilter}
                            onGetAssetsContent={getAssetsContent}
                        />
                    )}
                    {tab === 'collections' && (
                        <AssetManagementSelectorCollections
                            collectionsList={collectionsListState.collectionsList}
                            filters={collectionsListState.collectionFilters}
                            filterSetup={collectionFilterSetup}
                            hasMore={Boolean(collectionsListState.collectionsNextPageToken)}
                            sortField={collectionsListState.collectionSortField}
                            sortDirection={collectionsListState.collectionSortDirection}
                            loading={collectionsLoading}
                            onSelectCollection={setOpenCollection}
                            onChangeFilter={changeCollectionsFilter}
                            onClearFilter={clearCollectionsFilter}
                            onGetCollectionsContent={getCollectionsContent}
                        />
                    )}
                </React.Fragment>
            )}
            {openCollection && (
                <AssetManagementSelectorCollection
                    dataFilters={dataFilters}
                    preFilters={preFilters}
                    categories={categories}
                    hideFilters={hideFilters}
                    openCollection={openCollection}
                    assetTile={assetTile}
                    selectedItemLoading={Boolean(selectedItemLoading)}
                    onSelect={onSelect}
                    onSelectCollection={setOpenCollection}
                    onSelectCollectionById={selectCollectionById}
                />
            )}
        </div>
    );
};

export default AssetManagementSelector;
