import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import classNames from 'classnames';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Select from 'components/ui-components-v2/Select';
import Translation from 'components/data/Translation';
import { SortDirection, SortField } from 'components/asset-management/types/asset-management.service.type';

import '../styles/display.scss';

interface Props {
    sortField?: SortField;
    sortDirection?: SortDirection;
    className?: string;
    onSort: (sortField: SortField, sortDirection: SortDirection) => void;
}

const OPTIONS = {
    'updatedAt-desc': Translation.get('sort.updatedDown', 'asset-management'),
    'updatedAt-asc': Translation.get('sort.updatedUp', 'asset-management'),
    'createdAt-desc': Translation.get('sort.createdDown', 'asset-management'),
    'createdAt-asc': Translation.get('sort.createdUp', 'asset-management'),
    'title-asc': Translation.get('sort.titleUp', 'asset-management'),
    'title-desc': Translation.get('sort.titleDown', 'asset-management')
};

const AssetManagementSortDropdownDisplay = ({ sortField = 'createdAt', sortDirection = 'desc', className, onSort }: Props) => {
    const onChange = (e) => {
        const values = e.target.value.split('-');
        onSort(values[0], values[1]);
    };

    return (
        <FormControl variant="outlined" className={classNames('asset-management-sort-assets-dropdown-display', className)}>
            <InputLabel margin="dense" htmlFor={'assets-sort-select'} shrink>
                {Translation.get('labels.sort', 'common')}
            </InputLabel>
            <Select
                margin="dense"
                value={`${sortField}-${sortDirection}`}
                label={Translation.get('labels.sort', 'common')}
                size="medium"
                onChange={onChange}
                inputProps={{
                    id: 'assets-sort-select'
                }}
                className="asset-management-sort-assets-dropdown-display__select"
                data-cy="assetManagement-sortOverview-select"
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }}>
                {Object.entries(OPTIONS).map(([key, value]) => (
                    <MenuItem key={key} value={key} data-cy="assetManagement-sortOverview-menuItem">
                        {value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default AssetManagementSortDropdownDisplay;
